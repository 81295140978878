import { Track, Waveform } from './types';
import { OGVPlayer } from 'ogv';
import { ConstructorReturnType, isIos } from '@/util';

let player: Player;
let playerBuilding: Promise<Player> | undefined;

export async function getPlayer(): Promise<Player> {
  if (playerBuilding) {
    return playerBuilding;
  }

  if (!player || player.destroyed) {
    if (isIos()) {
      playerBuilding = HowlerPlayer.build({
        mp3Fallback: true
      });
    } else {
      playerBuilding = NativePlayer.build();
    }
    player = await playerBuilding;
    playerBuilding = undefined;
  }

  return player;
}

export async function destroyPlayer() {
  const player = await getPlayer();

  player.destroy();
}

// Something that looks somewhat random and cool
export function defaultWaveform(
  bits: number,
  samples: number,
  n: number
): Waveform {
  const maxValue = Math.pow(2, bits) / 2 - 1;

  const data = new Array();
  const step = Math.PI * 2 * (1 / samples) * n;

  let val = 0;
  for (let i = 0; i < samples; i++) {
    const sine = Math.sin(val);
    const cosine = Math.cos(2.3 * val);

    // Max value in the middle
    const negativeExponential =
      -1 * Math.pow((i - samples / 2) / (samples / 20), 2) + maxValue;

    data.push(sine * cosine * negativeExponential);
    val += step;
  }

  return {
    bits,
    data
  };
}

export interface PlayerOptions {
  volume?: number;
  mp3Fallback?: boolean;
}

export abstract class Player {
  public abstract get destroyed(): boolean;

  public abstract get seek(): number;

  public abstract set seek(seconds: number);

  public abstract get volume(): number;

  public abstract set volume(v: number);

  public abstract async load(track: Track): Promise<void>;

  public abstract get playing(): boolean;

  public abstract async play(): Promise<void>;

  public abstract async pause(): Promise<void>;

  public abstract async stop(): Promise<void>;

  public abstract async unload(): Promise<void>;

  public abstract async destroy(): Promise<void>;

  public abstract onLoadStart(cb: (track: Track) => void): void;

  public abstract onLoad(cb: () => void): void;

  public abstract onPlay(cb: () => void): void;

  public abstract onPause(cb: () => void): void;

  public abstract onUpdate(cb: (seconds: number) => void): void;

  public abstract onEnd(cb: () => void): void;

  public abstract onError(cb: (error?: Error) => void): void;
}

export class NativePlayer extends Player {
  private loadStartCb?: (track: Track) => void;
  private lastTrack?: Track;

  private _destroyed: boolean = false;

  private constructor(
    private audio: OGVPlayer | HTMLAudioElement,
    private options?: PlayerOptions
  ) {
    super();
  }

  public static async build(options?: PlayerOptions): Promise<Player> {
    if (!options) {
      options = {};
    }

    let player = new Audio();

    if (!player.canPlayType('audio/ogg')) {
      if (process.env.VUE_APP_USE_OGV_JS === 'true') {
        const ogv = await import('ogv');
        ogv.OGVLoader.base = '/ogvjs/';
        player = new ogv.OGVPlayer({ wasm: false });
      } else {
        options.mp3Fallback = true;
      }
    }

    player.style.display = 'none';
    document.body.appendChild(player);

    return new NativePlayer(player, options);
  }

  public get destroyed(): boolean {
    return this._destroyed;
  }

  public get seek(): number {
    return this.audio.currentTime;
  }

  public set seek(seconds: number) {
    this.audio.currentTime = seconds;
  }

  public get volume(): number {
    return this.audio.volume;
  }

  public set volume(v: number) {
    this.audio.volume = Math.pow(v, 2);
  }

  public async load(track: Track): Promise<void> {
    if (this.lastTrack?.id === track.id) {
      this.seek = 0;
      return;
    }

    if (this.loadStartCb) {
      this.loadStartCb(track);
    }

    const loadPromise = new Promise<void>((resolve, reject) => {
      if (!track.url || track.url.length === 0) {
        throw new Error(`missing url for track ${track.id}`);
      }

      const onLoad = () => {
        resolve();

        this.audio.removeEventListener('loadeddata', onLoad);
        this.audio.removeEventListener('error', onError);
      };

      const onError = (ev: ErrorEvent) => {
        reject(ev.error);

        this.audio.removeEventListener('loadeddata', onLoad);
        this.audio.removeEventListener('error', onError);
      };

      this.audio.addEventListener('error', onError);
      this.audio.addEventListener('loadeddata', onLoad);
      this.audio.src = track.url + (this.options?.mp3Fallback ? '.mp3' : '');
    });

    loadPromise.then(() => {
      this.lastTrack = track;
    });

    return loadPromise;
  }

  public get playing() {
    return !!(
      this.audio.currentTime > 0 &&
      !this.audio.paused &&
      !this.audio.ended &&
      this.audio.readyState > 2
    );
  }

  public async play(): Promise<void> {
    await this.audio.play();
  }

  public async pause(): Promise<void> {
    this.audio.pause();
  }

  public async stop(): Promise<void> {
    this.audio.pause();
    this.audio.currentTime = 0;
  }

  public async unload(): Promise<void> {
    this.audio.pause();
    this.audio.removeAttribute('src');
    this.audio.load();
    this.lastTrack = undefined;
  }

  public async destroy(): Promise<void> {
    this.audio.pause();
    this.audio.removeAttribute('src');
    this.audio.load();

    document.body.removeChild(this.audio);

    this._destroyed = true;
  }

  public onLoadStart(cb: (track: Track) => void) {
    this.loadStartCb = cb;
  }

  public onLoad(cb: () => void) {
    this.audio.onloadeddata = cb;
  }

  public onPlay(cb: () => void) {
    this.audio.onplaying = cb;
  }

  public onPause(cb: () => void) {
    this.audio.onpause = cb;
  }

  public onUpdate(cb: (seconds: number) => void) {
    this.audio.ontimeupdate = () => {
      cb(this.audio.currentTime);
    };
  }

  public onEnd(cb: () => void) {
    this.audio.onended = cb;
  }

  public onError(cb: (error?: Error) => void) {
    this.audio.onerror = (_event, _source, _line, _column, error) => {
      cb(error);
    };
  }
}

type HowlInstance = ConstructorReturnType<typeof import('howler').Howl>;

export class HowlerPlayer extends Player {
  private howl?: HowlInstance;

  private loadStartCb?: (track: Track) => void;
  private loadCb?: () => void;
  private playCb?: () => void;
  private pauseCb?: () => void;
  private endCb?: () => void;
  private errorCb?: (error: any) => void;

  private lastTrack?: Track;

  private constructor(
    private Howl: typeof import('howler').Howl,
    private options: PlayerOptions
  ) {
    super();
  }

  public static async build(options?: PlayerOptions): Promise<Player> {
    return new HowlerPlayer((await import('howler')).Howl, options ?? {});
  }

  public get destroyed(): boolean {
    return false;
  }

  public get seek(): number {
    return (this.howl?.seek() as number) ?? 0;
  }

  public set seek(seconds: number) {
    this.howl?.seek(seconds);
  }

  public get volume(): number {
    return this.howl?.volume() ?? 0;
  }

  public set volume(v: number) {
    this.options.volume = v;
    this.howl?.volume(Math.pow(this.options.volume, 2));
  }

  public async load(track: Track): Promise<void> {
    if (this.lastTrack?.id === track.id) {
      this.seek = 0;
      return;
    }

    await this.unload();

    if (this.loadStartCb) {
      this.loadStartCb(track);
    }

    const loadPromise = new Promise<void>((resolve, reject) => {
      if (!track.url || track.url.length === 0) {
        throw new Error(`missing url for track ${track.id}`);
      }

      this.howl = new this.Howl({
        src: track.url + (this.options?.mp3Fallback ? '.mp3' : ''),
        volume: this.options.volume ?? 0.5,
        preload: false
      });

      const onLoad = () => {
        resolve();
      };

      const onError = (_: any, ev: any) => {
        reject(ev);
      };

      this.howl.once('load', onLoad);
      this.howl.once('loaderror', onError);

      if (this.endCb) {
        this.howl.on('end', this.endCb);
      }

      if (this.errorCb) {
        this.howl.on('playerror', this.errorCb);
        this.howl.on('loaderror', this.errorCb);
      }

      if (this.loadCb) {
        this.howl.once('load', this.loadCb);
      }

      if (this.playCb) {
        this.howl.once('play', this.playCb);
      }

      if (this.pauseCb) {
        this.howl.once('pause', this.pauseCb);
      }

      this.howl.load();
    });

    loadPromise.then(() => {
      this.lastTrack = track;
    });

    return loadPromise;
  }

  public get playing(): boolean {
    return this.howl?.playing() ?? false;
  }

  public async play(): Promise<void> {
    this.howl?.play();
  }

  public async pause(): Promise<void> {
    this.howl?.pause();
  }

  public async stop(): Promise<void> {
    this.howl?.stop();
  }

  public async unload(): Promise<void> {
    this.howl?.unload();
    this.howl = undefined;
    this.lastTrack = undefined;
  }

  public async destroy(): Promise<void> {
    this.unload();
  }

  public onLoadStart(cb: (track: Track) => void): void {
    this.loadStartCb = cb;
  }

  public onLoad(cb: () => void): void {
    this.howl?.on('load', cb);
    this.loadCb = cb;
  }

  public onPlay(cb: () => void): void {
    this.howl?.on('play', cb);
    this.playCb = cb;
  }

  public onPause(cb: () => void): void {
    this.howl?.on('pause', cb);
    this.pauseCb = cb;
  }

  public onUpdate(_cb: (seconds: number) => void): void {
    throw new Error("Howler player doesn't support this");
  }

  public onEnd(cb: () => void): void {
    this.howl?.on('end', cb);
    this.endCb = cb;
  }

  public onError(cb: (error?: Error) => void): void {
    this.howl?.on('playerror', cb);
    this.howl?.on('loaderror', cb);
    this.errorCb = cb;
  }
}
