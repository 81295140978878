export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function pad(num: number, char: string, amount: number): string {
  return (char.repeat(amount) + String(num)).slice(String(num).length);
}

export function formatSeconds(seconds?: number): string {
  if (seconds === undefined) {
    return '00:00';
  }

  if (seconds <= 0) {
    return '00:00';
  }

  return `${Math.floor(seconds / 60)}:${pad(Math.floor(seconds % 60), '0', 2)}`;
}

export function clone<T = any>(value: T): T {
  if (value === undefined || value === null || typeof value === 'function') {
    return value;
  }

  return JSON.parse(JSON.stringify(value));
}

export function isIos(): boolean {
  return (
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream
  );
}

export type ConstructorReturnType<T> = T extends new (...args: any) => infer U
  ? U
  : never;
